<template>
  <div>
    <div v-if="isMobile===false" >
      <div class="box" v-if="languageSelect==='zh'">
      <div class="ViewTitle">账户安全</div>
      <div class="list">
        <div :class="[!userInfo.phone ? 'error' : '', 'item']">
          <div class="icon">
            <img src="@/assets/secure1.png" alt="" />
          </div>
          <div class="content">
            <div class="title">手机号绑定</div>
            <p class="text2" v-if="userInfo.phone">
              已绑定手机号<span class="red">{{ userInfo.phone }} </span
            >，绑定手机号可以快速找回密码和验证身份，保证您的账号安全
            </p>
            <p class="text1" v-else>您暂未进行手机绑定</p>
          </div>

          <div class="button" @click="toPhone()">
            <span v-if="userInfo.phone">立即修改</span>
            <span v-else>立即绑定</span>
          </div>
        </div>
        <div :class="[!userInfo.email ? 'error' : '', 'item']">
          <div class="icon">
            <img src="@/assets/secure2.png" alt="" />
          </div>
          <div class="content">
            <div class="title">邮箱绑定</div>
            <p class="text2" v-if="userInfo.email">
              已绑定邮箱号<span class="red">{{ userInfo.email }}</span
            >，绑定邮箱可以快速找回密码和验证身份，保证您的账号安全
            </p>
            <p class="text1" v-else>您暂未进行邮箱绑定</p>
          </div>

          <div class="button" @click="toEmail()">
            <span v-if="userInfo.email">立即修改</span>
            <span v-else>立即绑定</span>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img src="@/assets/secure3.png" alt="" />
          </div>
          <div class="content">
            <div class="title">登录密码</div>
            <p class="text1">
              安全性高的密码可以使账号更安全，建议您定期更换密码
            </p>
          </div>

          <div class="button" @click="toPassword()">
            <span>立即修改</span>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img src="@/assets/secure4.png" alt="" />
          </div>
          <div class="content">
            <div class="title">微信绑定</div>
            <p class="text2" v-if="userInfo.unionid">已绑定微信，立即解绑</p>
            <p class="text1" v-else>绑定微信，可实现快捷登录</p>
          </div>

          <div class="button" @click="toWeixin()">
            <span v-if="userInfo.unionid">立即解绑</span>
            <span v-else>立即绑定</span>
          </div>
        </div>
      </div>
      <el-dialog
          title="温馨提示"
          :visible.sync="dialogVisible"
          width="480px"
          :before-close="handleClose"
          top="40vh"
      >
        <span>是否确认解除绑定，解除绑定后，无法通过微信进行登录</span>
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose"> 取消 </el-button>
        <el-button type="primary" @click="handTo"> 确认 </el-button>
      </span>
      </el-dialog>
    </div>
      <div class="box" v-if="languageSelect==='en'">
        <div class="ViewTitle">Account security</div>
        <div class="list">
          <div :class="[!userInfo.phone ? 'error' : '', 'item']">
            <div class="icon">
              <img src="@/assets/secure1.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Mobile phone number binding</div>
              <p class="text2" v-if="userInfo.phone">
                The mobile phone number<span class="red">{{ userInfo.phone }} </span
              >has been bound，Binding the mobile phone number can quickly retrieve the password and verify the identity, to ensure the security of your account
              </p>
              <p class="text1" v-else>You are not currently bound to your phone</p>
            </div>

            <div class="button" @click="toPhone()">
              <span v-if="userInfo.phone" >Immediate modification</span>
              <span v-else>Immediate binding</span>
            </div>
          </div>
          <div :class="[!userInfo.email ? 'error' : '', 'item']">
            <div class="icon">
              <img src="@/assets/secure2.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Mailbox binding</div>
              <p class="text2" v-if="userInfo.email">
                The mailbox<span class="red">{{ userInfo.email }}</span
              >has been bound，Binding mailbox can quickly retrieve password and verify identity, to ensure the security of your account
              </p>
              <p class="text1" v-else>You have not yet bound your mailbox</p>
            </div>

            <div class="button" @click="toEmail()">
              <span v-if="userInfo.email">Immediate modification</span>
              <span v-else>Immediate binding</span>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/secure3.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Login password</div>
              <p class="text1">
                You are advised to change your password periodically because a secure password enhances account security
              </p>
            </div>

            <div class="button" @click="toPassword()">
              <span>Immediate modification</span>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/secure4.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Wechat binding</div>
              <p class="text2" v-if="userInfo.unionid">Have been bound to wechat, unbind immediately</p>
              <p class="text1" v-else>Binding wechat, can achieve quick login</p>
            </div>

            <div class="button" @click="toWeixin()">
              <span v-if="userInfo.unionid">Unbind immediately</span>
              <span v-else>Immediate binding</span>
            </div>
          </div>
        </div>
        <el-dialog
            title="Tips"
            :visible.sync="dialogVisible"
            width="480px"
            :before-close="handleClose"
            top="40vh"
        >
          <span>Do you want to confirm unbinding? After unbinding, you cannot log in through wechat</span>
          <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose"> Cancel </el-button>
        <el-button type="primary" @click="handTo"> Confirm </el-button>
      </span>
        </el-dialog>
      </div>
    </div>
    <div v-if="isMobile===true" class="m-secure-page">
      <Header :title="$t('user.secure')"></Header>

      <div class="m-secure-box">
        <van-cell :title="$t('user.bindPhone')" :value="maskPhone(userInfo.phone)" is-link @click="toPhone()" />
        <van-cell :title="$t('user.bindEmail')" is-link  @click="toEmail()" />
        <van-cell :title="$t('user.setPwd')" is-link  @click="toPassword()" />
        <van-cell :title="$t('user.bindWx')" :value="userInfo.unionid ? $t('user.unbindImmediately') : $t('user.immediateBinding')" is-link  @click="toWeixin()" />
      </div>
      
      <Bottom></Bottom>
      <!-- <div class="box1" v-if="languageSelect==='zh'">
        <div style="position:absolute;width: 45px;height: 45px;margin-top: 12px" @click="slip()"><img src="../assets/slipleft.png"></div>

        <div class="ViewTitle">账户安全</div>
        <div class="list">
          <div :class="[!userInfo.phone ? 'error' : '', 'item']">
            <div class="icon">
              <img src="@/assets/secure1.png" alt="" />
            </div>
            <div class="content">
              <div class="title">手机号绑定</div>
              <p class="text2" v-if="userInfo.phone">
                已绑定手机号<span class="red">{{ userInfo.phone }} </span
              >，绑定手机号可以快速找回密码和验证身份，保证您的账号安全
              </p>
              <p class="text1" v-else>您暂未进行手机绑定</p>
            </div>

            <div class="button" @click="toPhone()">
              <span v-if="userInfo.phone">立即修改</span>
              <span v-else>立即绑定</span>
            </div>
          </div>
          <div :class="[!userInfo.email ? 'error' : '', 'item']">
            <div class="icon">
              <img src="@/assets/secure2.png" alt="" />
            </div>
            <div class="content">
              <div class="title">邮箱绑定</div>
              <p class="text2" v-if="userInfo.email">
                已绑定邮箱号<span class="red">{{ userInfo.email }}</span
              >，绑定邮箱可以快速找回密码和验证身份，保证您的账号安全
              </p>
              <p class="text1" v-else>您暂未进行邮箱绑定</p>
            </div>

            <div class="button" @click="toEmail()">
              <span v-if="userInfo.email">立即修改</span>
              <span v-else>立即绑定</span>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/secure3.png" alt="" />
            </div>
            <div class="content">
              <div class="title">登录密码</div>
              <p class="text1">
                安全性高的密码可以使账号更安全，建议您定期更换密码
              </p>
            </div>

            <div class="button" @click="toPassword()">
              <span>立即修改</span>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/secure4.png" alt="" />
            </div>
            <div class="content">
              <div class="title">微信绑定</div>
              <p class="text2" v-if="userInfo.unionid">已绑定微信，立即解绑</p>
              <p class="text1" v-else>绑定微信，可实现快捷登录</p>
            </div>

            <div class="button" @click="toWeixin()">
              <span v-if="userInfo.unionid">立即解绑</span>
              <span v-else>立即绑定</span>
            </div>
          </div>
        </div>
        <el-dialog
            title="温馨提示"
            :visible.sync="dialogVisible"
            width="480px"
            :before-close="handleClose"
            top="40vh"
        >
          <span>是否确认解除绑定，解除绑定后，无法通过微信进行登录</span>
          <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose"> 取消 </el-button>
        <el-button type="primary" @click="handTo"> 确认 </el-button>
      </span>
        </el-dialog>
      </div>
      <div class="box1" v-if="languageSelect==='en'">
        <div style="position:absolute;width: 45px;height: 45px;margin-top: 12px" @click="slip()"><img src="../assets/slipleft.png"></div>

        <div class="ViewTitle">Account security</div>
        <div class="list">
          <div :class="[!userInfo.phone ? 'error' : '', 'item']">
            <div class="icon">
              <img src="@/assets/secure1.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Mobile phone number binding</div>
              <p class="text2" v-if="userInfo.phone">
                The mobile phone number<span class="red">{{ userInfo.phone }} </span
              >has been bound，Binding the mobile phone number can quickly retrieve the password and verify the identity, to ensure the security of your account
              </p>
              <p class="text1" v-else>You are not currently bound to your phone</p>
            </div>

            <div class="button" @click="toPhone()">
              <span v-if="userInfo.phone" >Immediate modification</span>
              <span v-else>Immediate binding</span>
            </div>
          </div>
          <div :class="[!userInfo.email ? 'error' : '', 'item']">
            <div class="icon">
              <img src="@/assets/secure2.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Mailbox binding</div>
              <p class="text2" v-if="userInfo.email">
                The mailbox<span class="red">{{ userInfo.email }}</span
              >has been bound，Binding mailbox can quickly retrieve password and verify identity, to ensure the security of your account
              </p>
              <p class="text1" v-else>You have not yet bound your mailbox</p>
            </div>

            <div class="button" @click="toEmail()">
              <span v-if="userInfo.email">Immediate modification</span>
              <span v-else>Immediate binding</span>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/secure3.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Login password</div>
              <p class="text1">
                You are advised to change your password periodically because a secure password enhances account security
              </p>
            </div>

            <div class="button" @click="toPassword()">
              <span>Immediate modification</span>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="@/assets/secure4.png" alt="" />
            </div>
            <div class="content">
              <div class="title">Wechat binding</div>
              <p class="text2" v-if="userInfo.unionid">Have been bound to wechat, unbind immediately</p>
              <p class="text1" v-else>Binding wechat, can achieve quick login</p>
            </div>

            <div class="button" @click="toWeixin()">
              <span v-if="userInfo.unionid">Unbind immediately</span>
              <span v-else>Immediate binding</span>
            </div>
          </div>
        </div>
        <el-dialog
            title="Tips"
            :visible.sync="dialogVisible"
            width="480px"
            :before-close="handleClose"
            top="40vh"
        >
          <span>Do you want to confirm unbinding? After unbinding, you cannot log in through wechat</span>
          <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose"> Cancel </el-button>
        <el-button type="primary" @click="handTo"> Confirm </el-button>
      </span>
        </el-dialog>
      </div> -->
    </div>
    <!-- <BottomBanner v-if="isMobile === true"></BottomBanner> -->
  </div>

</template>
<script>
import { unBind, getWxCode } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import Bottom from "@/components/mobile/Bottom.vue";
import Header from "@/components/mobile/Header.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import {  Cell } from 'vant';

export default {
  name: "Secure",
  components: {
    [Cell.name]: Cell,
    Bottom,
    Header
  },
  data() {
    return {
      isMobile: false,
      baseURL: this.$utils.baseURL,
      userInfo: this.$store.state.user,
      dialogVisible: false,
      languageSelect: '',
    };
  },
  computed: {},
  watch: {
    "$store.state.user"(newValue) {
      this.userInfo = newValue;
    },
  },
  created() {
    localStorage.setItem('myclick',1)
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气知识',
        text:'洞悉油气行业变化，体验AI大模型，就来油气知识！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;

  },
  methods: {
    maskPhone(num){
      return num.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    },
    slip(){
      this.$router.go(-1)
    },
    toPhone() {
      if (this.userInfo.phone) {
        this.$router.push({ path: "/changePhone", query: { type: 1 } });
      } else {
        this.$router.push("/changePhone");
      }
    },
    toEmail() {
      if (this.userInfo.phone) {
        this.$router.push({ path: "/changeEmail", query: { type: 1 } });
      } else {
        this.$router.push("/changeEmail");
      }
    },
    toPassword() {
      this.$router.push("/changePassword");
    },
    toWeixin() {
      if (this.userInfo.unionid) {
        this.dialogVisible = true;

        // this.$router.push({ path: "/wechatCallback", query: { type: 1 } });
      } else {
        let url = this.baseURL + "/success";
        getWxCode(url).then((res) => {
          window.location.href = res.data.url;
        });
      }
    },
    handTo() {
      unBind().then(() => {
        this.dialogVisible = false;
        this.$message({
          message: "解绑成功!", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  background: #fff;
  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }

  .list {
    padding: 0 26px;
    .item {
      padding: 40px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 28px;
        height: 28px;
        margin-right: 26px;
      }
      .content {
        flex: 1;
        width: 0;
        .title {
          font-size: 18px;
          color: #222222;
          margin-bottom: 8px;
        }
        p {
          font-size: 12px;
          color: #333333;
        }
      }
      .button {
        width: 158px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #ebebeb;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .item:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
}
.box1 {
  background: #fff;
  width: 93vw;
  margin:5px auto;
  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 50px;
  }

  .list {
    padding: 0 26px;
    .item {
      padding: 40px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 28px;
        height: 28px;
        margin-right: 26px;
      }
      .content {
        flex: 1;
        width: 0;
        .title {
          font-size: 18px;
          color: #222222;
          margin-bottom: 8px;
        }
        p {
          font-size: 12px;
          color: #333333;
        }
      }
      .button {
        width: 158px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #ebebeb;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .item:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
}
.red {
  color: var(--main-color) !important;
}

.m-secure-page{
  background-color: #f8f8f8;
  .m-secure-box{
    min-height: 74vh;
    margin: 0 20px;
  }
}

</style>

